<template>
  <section>
    <v-form ref="forgetPasswordFormOTP">
      <v-text-field
        v-model="otp"
        outlined
        placeholder="OTP Code"
        :rules="otpRule"
      ></v-text-field>
      <v-text-field
        v-model="password"
        outlined
        placeholder="New Password"
        :rules="[rules.required]"
      ></v-text-field>

      <v-btn @click="validate" large v-if="!loading">Confirm</v-btn>
      <v-btn loading v-if="loading"></v-btn>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "ForgetPasswordOTPForm",
  data: () => ({
    showpassword: false,
    otp:undefined,
    password:undefined,
    otpRule: [(v) => !!v || "OTP is required."],
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    loading:false
  }),
  methods: {
    ...mapActions(["postForgetPasswordVerify"]),
    async validate() {
      if (this.$refs.forgetPasswordFormOTP.validate()) {
        this.loading = true;
        let data = {};
        data.otp = this.otp;
        data.password = this.password;
        await this.postForgetPasswordVerify(data);
        if (this.allForgetPasswordVerify.status == 200) {
          this.$router.push("/login");
        } else {
          this.snackbar = true;
          this.message = this.allForgetPasswordVerify.data.message;
          this.color = "red";
        }
        this.loading = false
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allForgetPasswordVerify"]),
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100% !important;
  background: #0878d4 !important;
  min-height: 55px;
}
</style>